app-input-validation {
    .bi {
        display: inline-block;
        @extend .mr-xs;
    }
    p {
        @extend .f-s;
    }
    .success-feedback {
        color: $green;
    }
    .error-feedback {
        color: $red;
    }
}

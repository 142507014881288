////////////////////////////////////////////////////
//
//              1. Typography
//
////////////////////////////////////////////////////

$font-family-sans-serif:
 // Safari for OS X and iOS (San Francisco)
    -apple-system,
    // Chrome >= 56 for OS X (San Francisco), Windows, Linux and Android
    system-ui,
    // Chrome < 56 for OS X (San Francisco)
    BlinkMacSystemFont,
    // Windows
    'Segoe UI',
    // Android
    'Roboto',
    // Basic web fallback
    'Helvetica Neue',
    Arial, sans-serif !default;

//
// Base font size for the body
// Every rem and em size are computed from this value
//
$font-size-base: 16px;

//
// Base line-height for the body
// Every rem and em size are computed from this value
//
$line-height-base: 1.3;

//
//  Font grid ratio
//  Theses ratio are used to compute the font sizes based on the font-base
//  @see https://www.dropbox.com/s/m3707qgpebnl91e/css-grid-ratio.xlsx?dl=0
//
$font-xxs: 8px / $font-size-base;
$font-xs: 12px / $font-size-base;
$font-s: 16px / $font-size-base;
$font-m: 20px / $font-size-base;
$font-l: 24px / $font-size-base;
$font-xl: 32px / $font-size-base;
$font-xxl: 48px / $font-size-base;

////////////////////////////////////////////////////
//
//              2. Grid
//
////////////////////////////////////////////////////

//om
// Base grid size
// Every padding margin are computed from this value
//

$grid-base: 16px;

//
// Container size
// The container will take this width in all the application.
// Default is 960px.
//
// Values can be :
// - in px to fix a specific width;
// - 100% to have a fluid container.
//

$container: 1140px;

//
// Columns count
// Default columns number is 12.
//

$columns: 12;

//
// External gutters size
// Defines if gutters are visible outside the grid, if yes, specify a value in px.
// Default is false.
//
// Values can be :
// - false (equals 0px)
// - {n}px to have a costum size.
//

$external-gutters: false;

//
// Internal gutters size
// Defines the gutters size.
// Default is 20px.
//
// Values can be :
// - false (equals 0px)
// - {n}px to have a costum size.
//

$internal-gutters: false;

//
//  Grid ratio
//  Theses ratio are used to compute the grid sizes based on the grid-base
//  @see https://www.dropbox.com/s/m3707qgpebnl91e/css-grid-ratio.xlsx?dl=0
//

$grid-xxs: 4px / $grid-base;
$grid-xs: 8px / $grid-base;
$grid-s: 12px / $grid-base;
$grid-m: 16px / $grid-base;
$grid-l: 24px / $grid-base;
$grid-xl: 32px / $grid-base;
$grid-xxl: 64px / $grid-base;

//
//  Responsive steps
//  Steps defined in the responsive utility
//

$responsive-xxs: map-get($grid-breakpoints, 'xxs');
$responsive-xs: map-get($grid-breakpoints, 'xs');
$responsive-s: map-get($grid-breakpoints, 'sm');
$responsive-m: map-get($grid-breakpoints, 'md');
$responsive-l: map-get($grid-breakpoints, 'lg');
$responsive-xl: map-get($grid-breakpoints, 'xl');
$responsive-xxl: map-get($grid-breakpoints, 'xxl');

////////////////////////////////////////////////////
//
//              3. Border
//
////////////////////////////////////////////////////

//
// Base border size
// Every border width and radius are computed from this value
//
$border-base: 2;

//
// Border radius multiplier
// A multiplier is applied on top of the ratio to compute the size of the radius
//
$border-radius-multiplier: 2;

//
// Shadow multiplier
// A multiplier is applied on top of the ratio to compute the size of the shadow
//
$box-shadow-multiplier: 2.45;

//
// Shadow color
// The shadow color that will be used
//
$box-shadow-color: transparentize($grey1, 0.8);

//
//  Border ratio
//  Theses ratio are used to compute the border sizes and radius based on the border-base
//  @see https://www.dropbox.com/s/m3707qgpebnl91e/css-grid-ratio.xlsx?dl=0
//
$border-xxs: 0.5;
$border-xs: 0.75;
$border-s: 0.875;
$border-m: 1;
$border-l: 2;
$border-xl: 3;
$border-xxl: 5;
$border-xxxl: 6;

////////////////////////////////////////////////////
//
//              4. Path
//
////////////////////////////////////////////////////

$image-path: '/build/images/';

////////////////////////////////////////////////////
//
//              4. General Sizes
//
////////////////////////////////////////////////////

$xs: 12px;
$s: 16px;
$m: 20px;
$l: 24px;
$xl: 32px;
$xxl: 48px;

$nav-height: 120px;
$nav-height-mobile: 80px;

$icon-xs: 12px;
$icon-s: 16px;
$icon-m: 20px;
$icon-l: 24px;

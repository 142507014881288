.input-control {
    width: 100%;
}

app-input-radio,
app-input-checkbox {
    display: block;
    input,
    label {
        cursor: pointer;
    }
    .custom-control-label {
        font-weight: 500;
    }
    .custom-control-input {
        margin-top: 0.1em;
        background-color: $white;

        &:focus {
            box-shadow: none;
            border: 1px solid rgba(0, 0, 0, 0.25);
        }
        &:checked,
        &:active {
            background-color: $purple;
            border-color: $purple;
            box-shadow: none;
        }
    }
}

app-input-switch {
    display: block;
    input,
    label {
        cursor: pointer;
    }
    label {
        line-height: 1.5em;
    }
    .custom-control-label {
        font-weight: 500;
    }
    .custom-control-input {
        margin-top: 0.25em;
        background-color: $white;

        &:focus {
            box-shadow: none;
            border: 1px solid rgba(0, 0, 0, 0.25);
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e") !important;
        }

        &:checked,
        &:active {
            filter: brightness(100%);
            background-color: $purple;
            border-color: $purple;
            box-shadow: none;
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 1%29'/%3e%3c/svg%3e") !important;
        }
    }
}

app-input-select {
    display: block;
    .custom-control-select {
        font-weight: 500;
        cursor: pointer;
        &:focus {
            box-shadow: none;
            border: 1px solid rgba(0, 0, 0, 0.25);
        }
    }
}

app-input-text {
    display: block;
    .custom-control-input {
        &:focus,
        &:active {
            box-shadow: none;
            border: 1px solid rgba(0, 0, 0, 0.25);
        }
    }
}

$purple: #a50f44;
$yellow: #f7b338;
$white: #ffffff;
$black: #0f182a;
$grey1: #797e88;
$grey2: #bfc1ce;
$grey3: #f7f7f7;
$grey4: #f0f0f0;

$green: #0fa55f;
$red: #ca2d2d;

$primary: $purple;
$secondary: $yellow;
$tertiary: $black;
$bg-color: $grey3;
$border-color: $grey4;

////////////////////////////////////////////////////
//
//              1. Width
//
////////////////////////////////////////////////////

@include border-width('xxs', $border-xxs);
@include border-width('xs', $border-xs);
@include border-width('s', $border-s);
@include border-width('m', $border-m);
@include border-width('l', $border-l);
@include border-width('xl', $border-xl);
@include border-width('xxl', $border-xxl);
@include border-width('xxxl', $border-xxxl);

.bw-none {
    border: 0;
}

////////////////////////////////////////////////////
//
//              2. Radius
//
////////////////////////////////////////////////////

@include border-radius('xxs', $border-xxs);
@include border-radius('xs', $border-xs);
@include border-radius('s', $border-s);
@include border-radius('m', $border-m);
@include border-radius('l', $border-l);
@include border-radius('xl', $border-xl);
@include border-radius('xxl', $border-xxl);
@include border-radius('xxxl', $border-xxxl);

.br-none {
    border-radius: 0;
}
.br-circle {
    border-radius: 50%;
}

////////////////////////////////////////////////////
//
//              3. Unequal radius
//
////////////////////////////////////////////////////

@include border-radius-top-left('xxs', $border-xxs);
@include border-radius-top-left('xs', $border-xs);
@include border-radius-top-left('s', $border-s);
@include border-radius-top-left('m', $border-m);
@include border-radius-top-left('l', $border-l);
@include border-radius-top-left('xl', $border-xl);
@include border-radius-top-left('xxl', $border-xxl);
@include border-radius-top-left('xxxl', $border-xxxl);
.brtl-none {
    border-top-left-radius: 0;
}

@include border-radius-top-right('xxs', $border-xxs);
@include border-radius-top-right('xs', $border-xs);
@include border-radius-top-right('s', $border-s);
@include border-radius-top-right('m', $border-m);
@include border-radius-top-right('l', $border-l);
@include border-radius-top-right('xl', $border-xl);
@include border-radius-top-right('xxl', $border-xxl);
@include border-radius-top-right('xxxl', $border-xxxl);
.brtr-none {
    border-top-right-radius: 0;
}

@include border-radius-bottom-left('xxs', $border-xxs);
@include border-radius-bottom-left('xs', $border-xs);
@include border-radius-bottom-left('s', $border-s);
@include border-radius-bottom-left('m', $border-m);
@include border-radius-bottom-left('l', $border-l);
@include border-radius-bottom-left('xl', $border-xl);
@include border-radius-bottom-left('xxl', $border-xxl);
@include border-radius-bottom-left('xxxl', $border-xxxl);
.brbl-none {
    border-bottom-left-radius: 0;
}

@include border-radius-bottom-right('xxs', $border-xxs);
@include border-radius-bottom-right('xs', $border-xs);
@include border-radius-bottom-right('s', $border-s);
@include border-radius-bottom-right('m', $border-m);
@include border-radius-bottom-right('l', $border-l);
@include border-radius-bottom-right('xl', $border-xl);
@include border-radius-bottom-right('xxl', $border-xxl);
@include border-radius-bottom-right('xxxl', $border-xxxl);
.brbr-none {
    border-bottom-right-radius: 0;
}

////////////////////////////////////////////////////
//
//              4. Style
//
////////////////////////////////////////////////////

@include border-style-solid();

.bs-dotted {
    border-style: dotted;
}

app-opportunities-list {
    display: flex;
    flex-wrap: wrap;
    gap: $s;
    justify-content: center;
}

app-opportunities-filters {
    margin: auto;
    margin-bottom: $l;
    display: block;
    position: sticky;
    top: $nav-height;
    width: 100%;
    max-width: 600px;

    .card {
        .header {
            display: flex;
            justify-content: space-between;
            cursor: pointer;
            @extend .mb-s;

            .title {
                display: flex;
                @extend .f-s;
                font-weight: 500;
                .bi {
                    display: inline-block;
                    @extend .mr-s;
                }
            }
            .collapse-btn {
                cursor: pointer;
                .bi:before {
                    font-size: $icon-m;
                }
            }
        }
        .sort .options {
            @extend .mt-s;
        }
        .options {
            @extend .pt-s;
        }
        .filters,
        .sort {
            @extend .mb-xl;
        }
        form {
            app-input-radio,
            app-input-checkbox,
            .form-check {
                @extend .mb-s;
            }
        }
    }

    @media only screen and (max-width: 650px) {
        top: $nav-height-mobile;
    }
}

app-opportunities-details,
app-opportunity-card {
    .card {
        .title {
            display: flex;
            align-items: top;
            justify-content: space-between;

            .star {
                cursor: pointer;
                .bi {
                    color: $yellow;
                }
                .bi::before {
                    font-size: $icon-l;
                }
            }
            .clicked .bi:before {
                transform: scale(1.4);
            }
        }
        .tags {
            .tag {
                @extend .mr-xs;
            }
        }
        .details {
            .detail {
                @extend .mb-m;
                .bi {
                    @extend .mr-s;
                }
                span {
                    font-weight: 500;
                }
            }
        }
    }
}

app-opportunity-card {
    display: block;
    width: 100%;
    max-width: 600px;
    transition: transform 200ms;
    cursor: pointer;

    &:hover {
        transform: scale(0.99);
    }

    .card {
        .title {
            @extend .mb-l;
            h3 {
                color: $purple;
                @extend .f-l;
                font-weight: 700;
                @extend .mr-s;
            }
        }
        @media only screen and (max-width: 450px) {
            .title {
                h3 {
                    font-size: $m;
                }
            }
        }
        .separator {
            @extend .my-s;
        }
        .footer {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .time {
                .bi {
                    @extend .mr-s;
                }
            }
            .views {
                .bi {
                    @extend .ml-s;
                    position: relative;
                    top: 2px;
                }
                .bi::before {
                    font-size: $icon-m;
                }
            }
        }
    }
}

app-opportunities-details {
    .back {
        display: inline-block;
        @extend .mb-l;
        .bi {
            transition: all 200ms;
            display: inline-block;
            @extend .mr-xs;
            font-size: $icon-m;
            position: relative;
            top: 1px;
        }
        span {
            font-weight: 500;
        }
        &:hover {
            .bi {
                transform: translateX(-2px);
            }
        }
    }
    .opportunity {
        .card {
            @extend .mb-xl;
            .title {
                h2 {
                    color: $purple;
                    @extend .f-xl;
                    @extend .mr-s;
                }
            }
            .star {
                .bi::before {
                    font-size: $icon-l;
                }
            }
            .tags {
                @extend .py-l;
            }
            @media only screen and (max-width: 450px) {
                .title {
                    h2 {
                        font-size: $m;
                    }
                }
                .star {
                    .bi::before {
                        font-size: $icon-l;
                    }
                }
            }
        }
        .details {
            @extend .mb-xl;
        }
        .description {
            @extend .mb-xl;
            h4 {
                @extend .mb-m;
            }
        }
    }
    .suggestions {
        .suggestions-title {
            @extend .f-m;
            font-weight: 600;
            @extend .mb-m;
        }
        app-opportunity-card {
            @extend .mb-m;
        }
    }
}

app-news {
    .list {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: $s;
    }
}

app-article-preview {
    display: block;
    width: 100%;
    max-width: 600px;
    transition: all 200ms;

    .card {
        .title {
            h3 {
                color: $purple;
                @extend .f-l;
                font-weight: 700;
            }
            @extend .mb-m;
        }
        @media only screen and (max-width: 450px) {
            .title {
                h3 {
                    font-size: $m;
                }
            }
        }
        .date {
            color: $grey1;
            @extend .mb-m;
            .bi {
                @extend .mr-s;
            }
        }
        .preview {
            @extend .mb-m;
            height: 100px;
            overflow: hidden;
        }
    }
}

app-article {
    .back {
        display: inline-block;
        @extend .mb-l;
        .bi {
            transition: all 200ms;
            display: inline-block;
            @extend .mr-xs;
            font-size: $icon-m;
            position: relative;
            top: 1px;
        }
        span {
            font-weight: 500;
        }
        &:hover {
            .bi {
                transform: translateX(-2px);
            }
        }
    }
    .article {
        @extend .mb-xl;
        .title {
            @extend .mb-l;
            h2 {
                color: $purple;
                @extend .f-xl;
            }
        }
        .date {
            color: $grey1;
            @extend .mb-l;
            .bi {
                @extend .mr-s;
            }
        }
        .content {
            line-height: 1.5em;

            h1,
            h2,
            h3 {
                @extend .mb-m;
            }
            h4,
            h5,
            h6,
            p {
                @extend .mb-s;
            }
            a {
                color: $purple;
                text-decoration: none;
                line-break: anywhere;
            }
            ul {
                padding-left: $m;
            }
        }
        @media only screen and (max-width: 450px) {
            .title {
                h2 {
                    font-size: $l;
                }
            }
        }
    }
    .suggestions {
        .title {
            @extend .f-m;
            font-weight: 600;
            @extend .mb-m;
        }
        app-article-preview {
            @extend .mb-m;
        }
    }
}

////////////////////////////////////////////////////////////////////////////////////////////
//
//      Global Style
//
////////////////////////////////////////////////////////////////////////////////////////////

body {
    min-height: 100%;
    font-family: $font;
    color: $black;
    background-color: $bg-color;

    &.modal-opened,
    &.overflow-hidden {
        overflow: hidden;
    }
}

body * {
    outline: none;
    box-sizing: border-box;
}

main {
    padding-top: $nav-height;
    padding-bottom: 50px;

    &.auth-container {
        padding-top: 0px;
        padding-bottom: 0px;
    }
}

ul,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    padding: 0;
}

a {
    color: $black;
    text-decoration: none;
}

p {
    line-height: 1.5em;
}

@media only screen and (max-width: 650px) {
    main {
        padding-top: $nav-height-mobile;
        padding-bottom: 50px;
    }
}

////////////////////////////////////////////////////////////////////////////////////////////
//
//      Custom Style
//
////////////////////////////////////////////////////////////////////////////////////////////

.card {
    display: block;
    background-color: $white;
    border: 1px solid $border-color;
    border-radius: $s;
    @extend .p-l;
}

.separator {
    height: 1px;
    width: 100%;
    background-color: $border-color;
}

.red,
.purple,
.error {
    color: $purple;
}

.btns {
    display: flex;
    flex-wrap: wrap;
    gap: $s;

    @media only screen and (max-width: 460px) {
        .btn {
            width: 100%;
        }
    }
}

.btn {
    display: inline-block;
    cursor: pointer;
    border-radius: 6px;
    transition: all 200ms;
    text-decoration: none;
    text-align: center;
    line-height: 1;

    &:disabled {
        cursor: not-allowed;
    }

    &-primary {
        @extend .px-l;
        @extend .py-s;
        border: none;
        color: $white;
        background-color: $purple;

        &:disabled {
            background-color: #d286a1;
        }

        &:not(:disabled):not(.disabled):hover {
            transform: translateY(-2px);
        }
    }

    &-secondary {
        @extend .px-l;
        @extend .py-s;
        border: none;
        color: $white;
        background-color: $black;

        &:disabled {
            background-color: $grey2;
        }

        &:not(:disabled):not(.disabled):hover {
            transform: translateY(-2px);
        }
    }
}

.horizontal-flip {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.vertical-flip {
    -webkit-transform: scaleY(-1);
    transform: scaleY(-1);
}

.tag {
    display: inline-block;
    padding: 4px 8px;
    @extend .f-xs;
    font-weight: 500;
    border-radius: 3px;
    &-green {
        background-color: $green;
        color: $white;
    }
    &-purple {
        background-color: $purple;
        color: $white;
    }
    &-grey {
        background-color: $grey1;
        color: $white;
    }
    &-black {
        background-color: $black;
        color: $white;
    }
}

app-alert {
    .alert {
        @extend .py-m;
        position: fixed;
        width: 90%;
        max-width: 500px;
        opacity: 0;
        bottom: 2rem;
        left: 50%;
        overflow: hidden;
        transform: translate(-50%);
        transition: all 300ms ease-in-out;
        z-index: 100;

        .flex-wrapper {
            @extend .mb-xs;
            display: flex;
            align-items: center;
        }

        i.bi::before {
            @extend .pr-xs;
        }

        p {
            overflow: hidden;
            text-overflow: ellipsis;
        }

        @for $i from 0 through 2 {
            &.active.alert--position-#{$i} {
                opacity: 1;
                transform: translate(-50%, -($i * 110%));
            }
        }

        &:hover {
            cursor: pointer;
        }
    }
}

app-account {
    .profil,
    .notifications,
    .conditions {
        @extend .mb-xl;
    }
    .profil {
        .name,
        .mail,
        .lang {
            display: flex;
            align-items: center;
            .bi {
                width: 40px;
            }
        }
        .name {
            @extend .mb-m;
            h2 {
                @extend .f-l;
                font-weight: 600;
            }
            .bi::before {
                font-size: $icon-m;
            }
        }
        .mail,
        .lang {
            @extend .mb-l;
            span {
                font-weight: 500;
            }
            .bi::before {
                font-size: $icon-s;
            }
        }
    }
    .notifications,
    .conditions {
        h3 {
            @extend .f-m;
            font-weight: 600;
            @extend .mb-l;
        }
    }
    .notifications {
        app-input-switch {
            @extend .mb-m;
        }
        .switches {
            @extend .mb-l;
        }
    }
    .conditions {
        ul {
            @extend .mb-l;
            list-style-type: none;
            li {
                @extend .mb-s;
            }
            a {
                @extend .f-s;
                color: $purple;
            }
        }
        .credits {
            @extend .f-xs;
        }
    }
}

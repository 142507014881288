app-navbar {
    display: flex;
    width: 100%;
    height: $nav-height;
    justify-content: center;
    align-items: center;
    position: fixed;
    background-color: $bg-color;
    z-index: 10;

    nav {
        border-radius: $s;
        background: $yellow;
        background: linear-gradient(90deg, rgba(247, 179, 56, 1) 0%, rgba(165, 15, 68, 1) 100%);
        margin-bottom: $m;
        ul {
            @extend .px-s;
            list-style-type: none;
            display: flex;

            a {
                display: flex;
                align-items: center;
                @extend .py-m;
                @extend .px-l;
                color: $white;
                text-decoration: none;
                font-weight: 500;
                transition: all 200ms;
                .bi::before {
                    font-size: $icon-m;
                }
                // update the icon style (fill or not)
                .icon {
                    position: relative;
                    width: $m;
                    height: $m;
                    .bi {
                        position: absolute;
                    }
                    .inactive,
                    .active {
                        transition: all 200ms;
                    }
                }
                span {
                    @extend .pl-s;
                }
                .inactive {
                    opacity: 1;
                }
                .active {
                    opacity: 0;
                }
                &:hover {
                    .inactive {
                        opacity: 0;
                    }
                    .active {
                        opacity: 1;
                    }
                }
            }
            .is-active {
                .inactive {
                    opacity: 0;
                }
                .active {
                    opacity: 1;
                }
            }
        }
    }
}

@media only screen and (max-width: 650px) {
    app-navbar {
        height: $nav-height-mobile;
        nav {
            margin-bottom: 0px;
        }
        span {
            display: none;
        }
    }
}

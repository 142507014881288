app-login,
app-forgot-password,
app-reset-password {
    display: flex;
    align-items: center;
    background: linear-gradient(120deg, rgba(247, 179, 56, 1) 0%, rgba(165, 15, 68, 1) 100%);
    background-size: 120% 120%;
    height: 100vh;

    .card {
        margin-top: -10vh;
    }

    h1 {
        text-align: center;
        margin: $xl 0px $xxl 0px;
    }

    .input-control {
        @extend .mb-s;
        input {
            @extend .mb-xs;
        }
    }

    .forgot-password {
        display: block;
        width: 100%;
        text-align: right;
        @extend .mb-l;
    }
}
